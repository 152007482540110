<template>
    <div class="topNav">
        <div class="pw">
            <div class="topLeft">
                <template v-for="(item,i) in topnavList">
                    <a :href="showWater ? 'javascript:;' :item.url" :key="i" target="_blank">{{item.name}}</a>
                </template>
            </div>
            <div class="topRight">
                <a :href="showWater ? 'javascript:;':'https://artexpress.artron.net/download/express'" target="_blank" class="toutiaoApp">
                    <img src="../../assets/imgs/logo_toutiao.png" alt="">
                    艺术头条</a>
                <a :href="showWater ? 'javascript:;' : 'https://artexpress.artron.net/download/tulu'" target="_blank" class="tuluApp">
                    <img src="../../assets/imgs/logo_tulu.png" alt="">
                    雅昌拍卖图录</a>
                <a :href="showWater ? 'javascript:;' : 'https://cloud.artron.net/'" target="_blank">云艺+</a>
                <a :href="showWater ? 'javascript:;' :'https://artbase.artron.net'" target="_blank">Artbase</a>
                <div class="top-person">
                    <a href="javascript:;" class="beforeLogin" @click="gotoLogin" v-if="uid <=0">登录</a>
                    <el-dropdown v-if="uid > 0">
                        <span class="el-dropdown-link"><img :src="headerImg" alt=""></span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item>
                                <dl class="personHeader">
                                    <dt><img :src="headerImg" alt=""></dt>
                                    <dd>{{personName}}</dd>
                                </dl>
                            </el-dropdown-item>
                            <el-dropdown-item><a :href="showWater ? 'javascript:;' :'//member.artron.net'">用户中心</a></el-dropdown-item>
                            <el-dropdown-item><a :href="showWater ? 'javascript:;' :'//passport.artron.net/my/chgpasswd/'">修改密码</a></el-dropdown-item>
                            <el-dropdown-item><a href="javascript:;" @click="exit" class="exit">退出登录</a></el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import request from "axios"
export default {
    name: "topNav",
    props:['showWater'],
    data(){
        return{
            topnavList:[
                {
                    name:'雅昌艺术网',
                    url:'https://www.artron.net'
                },{
                    name:'雅昌指数',
                    url:'https://amma.artron.net'
                },{
                    name:'画廊',
                    url:'https://gallery.artron.net'
                },{
                    name:'拍卖',
                    url:'https://auction.artron.net'
                },{
                    name:'展览',
                    url:'https://exhibit.artron.net'
                },{
                    name:'雅昌课堂',
                    url:'https://video.artron.net'
                },{
                    name:'得艺',
                    url:'https://artplus.artron.net'
                },{
                    name:'新闻',
                    url:'https://news.artron.net'
                },{
                    name:'艺术家',
                    url:'https://artist.artron.net'
                },
                // {
                //     name:'在线拍',
                //     url:'https://zxp.artron.net'
                // }
            ],
            isLogin:false,
            uid:0,
            headerImg:'',
            personName:''
        }
    },
    mounted() {
        this.uid = this.getCookie('_at_pt_0_') && this.getCookie('_at_pt_0_') > 0 ? this.formatZero(this.getCookie('_at_pt_0_')) : 0;
        this.headerImg  = this.getCookie('_at_pt_0_') && this.getCookie('_at_pt_0_') > 0?'https://img12'+ '.artimg.net/passport/avatar/' +this.uid.substring(0, 3) + '/' + this.uid.substring(3, 6) + '/' + this.uid.substring(6, 9) + '/150_150.png?t='+new Date().getTime() : '';
        this.personName = this.getCookie('_at_pt_1_') ? decodeURI(this.getCookie('_at_pt_1_')) : '';
    },
    methods:{
        gotoLogin:function (){
            if(!this.showWater){
            window.location.href = '//passport.artron.net/login?appId=1&redirect='+encodeURIComponent(window.location.href)
            }
        },
        exit:function (){
            if(!this.showWater) {
                let href = window.location.href
                if(href.indexOf('artronvip.com')!=-1){
                    let hrefSearch = window.location.search
                    hrefSearch = hrefSearch.substring(0,hrefSearch.indexOf('&token_data='))
                    href = window.location.origin + window.location.pathname + hrefSearch
                    request({
                        method: 'get',
                        url: 'https://member.artronvip.com/memCenter/vip/logout',
                        withCredentials: true
                    }).then(res => {
                    })
                }
                window.location.href = '//passport.artron.net/memCenter/logout?redirect=' + encodeURIComponent(href)
            }
        },
        openPerson(){
            this.personShow = !this.personShow;
        },
        getCookie:function (name) {
            var arr;
            var reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
            if(document.cookie.match(reg)) {
                arr = document.cookie.match(reg);
                return arr[2];
            }else {
                return null;
            }
        },
        formatZero:function (num) {
            if(num.length < 9){
                num = (Array(10-num.length).join(0) + num);
                return num;
            }else{
                return num;
            }
        },
        getParamUrl: function (name) {
            return location.href.match(new RegExp('[?#&]' + name + '=([^?#&]+)', 'i')) ? RegExp.$1 : ''
        },

    }
}
</script>

<style scoped lang="scss">
.topNav{background-color: #262626;line-height: 42px;
    .pw{display:flex;justify-content: space-between;}
    .topLeft{width:700px;}
    a{color:#fff;position: relative;padding-right: 10px;margin-right: 10px;}
    a:hover{color:#0F820C}
    a:after{height:14px;width:1px;display:block;position: absolute;content:'';background-color: #fff;right:0;top:50%;transform: translateY(-50%);}
    a:last-child{padding-right: 0;margin-right: 0;}
    a:last-child:after{display:none;}
    .topRight{width:430px;text-align: right;display:flex;justify-content: flex-end;
        a{display:flex;align-items: center;
            img{width:24px;height: 24px;margin-right: 8px;}
        }
    }
    .toutiaoBox,.tuluBox{position: relative;color:#fff;padding-right: 10px;margin-right: 10px;}
    .toutiaoBox:after,.tuluBox:after{height:14px;width:1px;display:block;position: absolute;content:'';background-color: #fff;right:0;top:50%;transform: translateY(-50%);}
    .topLogin{cursor: pointer;color:#fff;}
    /deep/.el-dropdown{color:#fff;
        .el-dropdown-link{width:30px;height:30px;
            img{width:30px;height:30px;object-fit: cover;border-radius: 50%;overflow: hidden;}
        }
    }
    .top-person{
        .beforeLogin{padding-right: 0;margin-right: 0;}
        .beforeLogin:after{display:none;}
    }
}


</style>