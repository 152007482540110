var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "topNav" }, [
    _c("div", { staticClass: "pw" }, [
      _c(
        "div",
        { staticClass: "topLeft" },
        [
          _vm._l(_vm.topnavList, function (item, i) {
            return [
              _c(
                "a",
                {
                  key: i,
                  attrs: {
                    href: _vm.showWater ? "javascript:;" : item.url,
                    target: "_blank",
                  },
                },
                [_vm._v(_vm._s(item.name))]
              ),
            ]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "topRight" }, [
        _c(
          "a",
          {
            staticClass: "toutiaoApp",
            attrs: {
              href: _vm.showWater
                ? "javascript:;"
                : "https://artexpress.artron.net/download/express",
              target: "_blank",
            },
          },
          [
            _c("img", {
              attrs: {
                src: require("../../assets/imgs/logo_toutiao.png"),
                alt: "",
              },
            }),
            _vm._v("\n                艺术头条"),
          ]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "tuluApp",
            attrs: {
              href: _vm.showWater
                ? "javascript:;"
                : "https://artexpress.artron.net/download/tulu",
              target: "_blank",
            },
          },
          [
            _c("img", {
              attrs: {
                src: require("../../assets/imgs/logo_tulu.png"),
                alt: "",
              },
            }),
            _vm._v("\n                雅昌拍卖图录"),
          ]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            attrs: {
              href: _vm.showWater
                ? "javascript:;"
                : "https://cloud.artron.net/",
              target: "_blank",
            },
          },
          [_vm._v("云艺+")]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            attrs: {
              href: _vm.showWater
                ? "javascript:;"
                : "https://artbase.artron.net",
              target: "_blank",
            },
          },
          [_vm._v("Artbase")]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "top-person" },
          [
            _vm.uid <= 0
              ? _c(
                  "a",
                  {
                    staticClass: "beforeLogin",
                    attrs: { href: "javascript:;" },
                    on: { click: _vm.gotoLogin },
                  },
                  [_vm._v("登录")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.uid > 0
              ? _c(
                  "el-dropdown",
                  [
                    _c("span", { staticClass: "el-dropdown-link" }, [
                      _c("img", { attrs: { src: _vm.headerImg, alt: "" } }),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-dropdown-menu",
                      { attrs: { slot: "dropdown" }, slot: "dropdown" },
                      [
                        _c("el-dropdown-item", [
                          _c("dl", { staticClass: "personHeader" }, [
                            _c("dt", [
                              _c("img", {
                                attrs: { src: _vm.headerImg, alt: "" },
                              }),
                            ]),
                            _vm._v(" "),
                            _c("dd", [_vm._v(_vm._s(_vm.personName))]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("el-dropdown-item", [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.showWater
                                  ? "javascript:;"
                                  : "//member.artron.net",
                              },
                            },
                            [_vm._v("用户中心")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("el-dropdown-item", [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.showWater
                                  ? "javascript:;"
                                  : "//passport.artron.net/my/chgpasswd/",
                              },
                            },
                            [_vm._v("修改密码")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("el-dropdown-item", [
                          _c(
                            "a",
                            {
                              staticClass: "exit",
                              attrs: { href: "javascript:;" },
                              on: { click: _vm.exit },
                            },
                            [_vm._v("退出登录")]
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }